//------------------------------------------------------------------------------
import async                from 'async';
import { glMatrix, vec3 }   from 'gl-matrix';
import SDK3DVerse_Utils     from './Utils';

//------------------------------------------------------------------------------
/**
 * Handles the creation and retrieval of viewports, each of which is associated
 * to a camera. See {@link Viewport} class for more details.
 * 
 * @namespace SDK3DVerse.engineAPI.cameraAPI
 */

//------------------------------------------------------------------------------
const INFINITE_FAR_VALUE        = 100000;
const DEPRECATED_RENDER_GRAPH   = "67b13cc3-5b9d-41f0-9e73-7f94cd30d6d2";
const DEFAULT_RENDER_GRAPH      = "398ee642-030a-45e7-95df-7147f6c43392";

//------------------------------------------------------------------------------
export const controller_type =
{
    none    : -1,
    fps     : 0,
    orbit   : 1,
    fixed   : 2,
    editor  : 4
};

//------------------------------------------------------------------------------
export const projection_type =
{
    perspective     : 0,
    orthographic    : 1
};

//------------------------------------------------------------------------------
/**
 * Class representing a viewport in the canvas. The viewport is associated to a single camera entity and represents
 * a drawing area in the canvas where a frame is rendered according to the point of view of the camera.
 * The canvas may display several viewports.
 * 
 * You cannot directly instantiate a `Viewport` object by calling the constructor.
 * To set up a viewport, use one of the following methods: [startSession]{@link SDK3DVerse#startSession},
 * [joinSession]{@link SDK3DVerse#joinSession}, [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession},
 * [setViewports]{@link SDK3DVerse.engineAPI.cameraAPI#setViewports}, 
 * [setMainCamera]{@link SDK3DVerse.engineAPI.cameraAPI#setMainCamera}.
 * To get an existing viewport, use [getViewports]{@link SDK3DVerse.engineAPI.cameraAPI#getViewports}
 * or [getViewportById]{@link SDK3DVerse.engineAPI.cameraAPI#getViewportById}.
 */
export class Viewport
{
    //--------------------------------------------------------------------------
    static lookAtPoint = [0, 0, 0];

    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(id, onViewportUpdate, engineAPI)
    {
        this.id                     = id;
        this.onViewportUpdate       = onViewportUpdate;
        this.engineAPI              = engineAPI;

        this.widthRatio             = 0.0;
        this.heightRatio            = 0.0;

        this.leftRatio              = 0.0;
        this.topRatio               = 0.0;

        this.projectionType         = projection_type.perspective;
        this.zoomFactor             = 1.0;

        this.cameraEntity           = null;
        this.frameTransform         = null;
        this.defaultTransform       = null;

        this.worldMatrix            = mat4.create();
        this.viewMatrix             = mat4.create();
        this.projectionMatrix       = mat4.create();
        this.viewProjectionMatrix   = mat4.create();
    }

    //--------------------------------------------------------------------------
    /**
     * Get the viewport id.
     *
     * @returns {uint} The viewport id.
     */
    getId()
    {
        return this.id;
    }

    //--------------------------------------------------------------------------
    /** @private */
    setProperties(viewport)
    {
        this.widthRatio     = viewport.width;
        this.heightRatio    = viewport.height;

        this.leftRatio      = viewport.left;
        this.topRatio       = viewport.top;

        this.setProjectionType(viewport.isOrthographic
                                ? projection_type.orthographic
                                : projection_type.perspective);
    }

    //--------------------------------------------------------------------------
    /** @private */
    setFrameTransform(camera)
    {
        if(!this.frameTransform)
        {
            this.frameTransform = SDK3DVerse_Utils.clone(camera.UNSAFE_getComponent('local_transform'));
        }
    }

    //--------------------------------------------------------------------------
    /** @private */
    setWorldMatrix(matrix)
    {
        this.worldMatrix            = matrix;
        this.frameTransform         = SDK3DVerse_Utils.matrixToTransform(this.worldMatrix);
        this.frameTransform.scale   = [1.0, 1.0, 1.0];
        //this.cameraEntity.UNSAFE_setComponent('local_transform', SDK3DVerse_Utils.clone(this.frameTransform));

        this.updateViewMatrix();
    }

    //--------------------------------------------------------------------------
    /**
     * Get the world matrix of the camera.
     *
     * @returns {mat4} The world matrix.
     */
    getWorldMatrix()
    {
        return this.worldMatrix;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the camera entity associated with the viewport.
     *
     * @returns {Entity} The camera entity.
     */
    getCamera()
    {
        return this.cameraEntity;
    }

    //--------------------------------------------------------------------------
    /**
     * Get projection type of the viewport
     * @private
     *
     * @returns {uint} Projection type, identified as :
     *   * perspective : 0
     *   * orthographic : 1
     */
    getProjectionType()
    {
        return this.projectionType;
    }

    //--------------------------------------------------------------------------
    /**
     * Test if viewport's camera has a perspective projection.
     * @private
     *
     * @returns {boolean} Return true if the projection is perspective.
     */
    hasPerspectiveProjection()
    {
        return this.getProjectionType() === projection_type.perspective;
    }

    //--------------------------------------------------------------------------
    /**
     * Test if viewport's camera has an orthographic projection.
     * @private
     *
     * @returns {boolean} Return true if the projection is orthographic.
     */
    hasOrthographicProjection()
    {
        return this.getProjectionType() === projection_type.orthographic;
    }

    //--------------------------------------------------------------------------
    /** @private */
    setProjectionType(projectionType)
    {
        if(this.cameraEntity && this.projectionType !== projectionType)
        {
            if(this.projectionType === projection_type.perspective)
            {
                this.cameraEntity.detachComponent('perspective_lens');
                this.cameraEntity.attachComponent('orthographic_lens');
            }
            else
            {
                this.cameraEntity.detachComponent('orthographic_lens');
                this.cameraEntity.attachComponent('perspective_lens');
            }
        }

        this.projectionType = projectionType;
        this.computeProjectionData();
    }

    //--------------------------------------------------------------------------
    /**
     * Set the camera entity associated with the viewport.
     *
     * @param {Entity} camera -The camera entity
     */
    setCamera(camera, updateComponent = true)
    {
        if(!camera)
        {
            console.error('Attempt to attach an invalid camera to viewport', this);
            return;
        }

        this.cameraEntity = camera;
        this.updateProjectionMatrix(updateComponent);
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeProjectionData(newWidth, newHeight)
    {
        if(this.projectionType === projection_type.perspective)
        {
            this.computeAspectRatio(newWidth, newHeight);
        }
        else
        {
            this.computeOrthographicValues(newWidth, newHeight);
        }
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeAspectRatio(newWidth, newHeight)
    {
        const areaSize      = this.getAreaSize(newWidth, newHeight);
        this.aspectRatio    = areaSize[0] / areaSize[1];
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeOrthographicValues(newWidth, newHeight)
    {
        const areaSize      = this.getAreaSize(newWidth, newHeight);
        const aspectRatio   = areaSize[0] / areaSize[1];

        const width         = aspectRatio;
        const d             = 100;

        this.orthographicLens = {
            left    : -width * this.zoomFactor,
            right   : width * this.zoomFactor,
            top     : -this.zoomFactor,
            bottom  : this.zoomFactor,
            zNear   : 0,
            zFar    : d
        };
    }

    //--------------------------------------------------------------------------
    /**
     * Get the zoom factor of an orthographic projection (no effect with a perspective projection)
     * @private
     *
     * @returns {float} zoomFactor - The current zoom factor of the projection
     */
    getZoomFactor()
    {
        if(this.projectionType !== projection_type.orthographic)
        {
            console.warn('Attempt to call getZoomFactor on a non-orthographic projection, projection type = ' + this.projectionType)
            return;
        }

        return this.zoomFactor;
    }

    //--------------------------------------------------------------------------
    /**
     * Set the zoom factor of the orthographic projection of the viewport (no effect with a perspective projection).
     * It calls [updateControllerSettings]{@link SDK3DVerse.engineAPI.cameraAPI#updateControllerSettings}
     * to refresh the speed setting of the camera controller, depends on the zoom factor change.
     * @private
     *
     * @param {float} zoomFactor - Zoom Factor to apply
     */
    setZoomFactor(zoomFactor)
    {
        if(this.projectionType !== projection_type.orthographic)
        {
            console.warn('Attempt to call setZoomFactor, on a non-orthographic viewport, viewport type = ' + this.projectionType);
            return;
        }

        this.zoomFactor = zoomFactor;
        this.computeProjectionData();
        this.updateProjectionMatrix();

        // Refresh controller speed setting
        this.engineAPI.cameraAPI.updateControllerSettings({ speed: SDK3DVerse.engineAPI.cameraAPI.controllerSettings.speed });
    }

    //--------------------------------------------------------------------------
    /** @private */
    updateProjectionMatrix(updateComponent = true)
    {
        if(this.projectionType === projection_type.perspective)
        {
            const perspectiveLens = this.cameraEntity.getComponent('perspective_lens');

            if(updateComponent)
            {
                const value = {
                    ...perspectiveLens,
                    aspectRatio : this.aspectRatio
                };

                if(this.cameraEntity.isExternal())
                {
                    this.engineAPI.setOrCreateOverrider(this.cameraEntity, 'perspective_lens', value, true);
                }
                else
                {
                    this.cameraEntity.setComponent('perspective_lens', value);
                }
            }

            mat4.perspective(
                this.projectionMatrix,
                glMatrix.toRadian(perspectiveLens.fovy),
                this.aspectRatio,
                perspectiveLens.nearPlane,
                perspectiveLens.farPlane || INFINITE_FAR_VALUE
            );
        }
        else
        {
            if(updateComponent)
            {
                if(this.cameraEntity.isExternal())
                {
                    this.engineAPI.setOrCreateOverrider(this.cameraEntity, 'orthographic_lens', this.orthographicLens, true);
                }
                else
                {
                    this.cameraEntity.setComponent('orthographic_lens', this.orthographicLens);
                }
            }

            mat4.ortho(
                this.projectionMatrix,
                this.orthographicLens.left,
                this.orthographicLens.right,
                this.orthographicLens.top,
                this.orthographicLens.bottom,
                this.orthographicLens.zNear,
                this.orthographicLens.zFar,
            );
        }

        this.updateViewProjectionMatrix();
    }

    //--------------------------------------------------------------------------
    /** @private */
    updateViewMatrix()
    {
        mat4.invert(this.viewMatrix, this.worldMatrix);
        this.updateViewProjectionMatrix();
    }

    //--------------------------------------------------------------------------
    /** @private */
    updateViewProjectionMatrix()
    {
        mat4.multiply(this.viewProjectionMatrix, this.projectionMatrix, this.viewMatrix);
    }

    //--------------------------------------------------------------------------
    /**
     * Get the global transform of the camera.
     * This transform is updated each frame and provided by the metadata of the frame sent by the rendering service.
     *
     * @returns {Transform} The global transform of the viewport's camera.
     */
    getGlobalTransform()
    {
        return this.frameTransform;
    }

    //--------------------------------------------------------------------------
    // deprecated
    getTransform()
    {
        return this.frameTransform;
    }

    //--------------------------------------------------------------------------
    /**
     * Set the camera local transform.
     *
     * @param {Transform} transform - The transform component value
     * 
     * @fires onEntitiesUpdated
     */
    setLocalTransform(transform, propagateChanges = true, updateMatrices = false)
    {
        transform = SDK3DVerse_Utils.patchTransform(transform);
        this.cameraEntity.UNSAFE_setComponent('local_transform', transform);
        this.cameraEntity.UNSAFE_setDirty('local_transform');
        if(propagateChanges)
        {
            SDK3DVerse.engineAPI.ftlAPI.propagateChanges([this.cameraEntity]);
        }
        else
        {
            this.cameraEntity.engineAPI.entityRegistry.UNSAFE_setDirtyEntity(this.cameraEntity);
        }

        if(updateMatrices)
        {
            this.worldMatrix = this.cameraEntity.getGlobalMatrix();
            this.updateViewMatrix();
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    setTransform(transform, propagateChanges = true, updateMatrices = false)
    {
        this.setLocalTransform(transform, propagateChanges, updateMatrices);
    }

    //--------------------------------------------------------------------------
    /**
     * Set the camera global transform.
     *
     * @param {Transform} globalTransform - The transform component value
     * 
     * @fires onEntitiesUpdated
     */
    setGlobalTransform(globalTransform, propagateChanges = true, updateMatrices = false)
    {
        globalTransform = SDK3DVerse_Utils.patchTransform(globalTransform);

        this.cameraEntity.setGlobalTransform(globalTransform);
        if(propagateChanges)
        {
            SDK3DVerse.engineAPI.ftlAPI.propagateChanges([this.cameraEntity]);
        }

        // Cancel unsaved state in all case to prevent camera glitches when commit changes,
        // and dirty state only if propagate changes is true, because it would be updated,
        // with the ftlAPI.propagateChanges just above.
        this.cameraEntity.cancelComponentChanges('local_transform', propagateChanges, true);

        if(updateMatrices)
        {
            this.worldMatrix = this.cameraEntity.getGlobalMatrix();
            this.updateViewMatrix();
        }
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeOrientationToTarget(target)
    {
        const targetPosition    = vec3.fromValues(...target);
        const globalPosition    = this.getTransform().position;

        let direction   = vec3.create();
        vec3.sub(direction, globalPosition, targetPosition);
        vec3.normalize(direction, direction);

        let rightVector         = vec3.create();
        vec3.cross(rightVector, direction, SDK3DVerse_Utils.neutralUp);

        let upVector            = vec3.create();
        vec3.cross(upVector, rightVector, direction);

        let targetToMat         = mat4.create();
        mat4.targetTo(targetToMat, globalPosition, targetPosition, upVector);

        let targetOrientation   = quat.create();
        mat4.getRotation(targetOrientation, targetToMat);

        return {
            targetOrientation : Array.from(targetOrientation),
            direction
        };
    }

    //--------------------------------------------------------------------------
    /**
     * Animate camera traveling from starting position and orientation to destination position
     * and orientation at a specified speed. Inputs are disabled during travel.
     *
     * @param {SDK_Vec3} destinationPosition Destination position of camera
     * @param {SDK_Quat} destinationOrientation Destination orientation of camera
     * @param {number} speed Travel speed. Duration of travel is equal to distance / speed
     * @param {SDK_Vec3} [startPosition] Start position of camera, defaults to current position
     * @param {SDK_Quat} [startOrientation] Start orientation of camera, defaults to current orientation
     *
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * await viewport.travel([0,0,0], [0,0,0,1], 10);
     * 
     * @fires onEntitiesUpdated
     * 
     * @async
     */
    travel(destinationPosition, destinationOrientation, speed, startPosition, startOrientation)
    {
        this.engineAPI.cameraAPI.streamer.inputRelay.suspendInputs();

        const intervalFrequency = 30;

        const currentCameraTransform =
        {
            position    : vec3.fromValues(...(startPosition || this.getTransform().position)),
            orientation : quat.fromValues(...(startOrientation || this.getTransform().orientation))
        };

        const destinationTransform =
        {
            position    : vec3.fromValues(...destinationPosition),
            orientation : quat.fromValues(...destinationOrientation)
        };

        const distance          = vec3.distance(currentCameraTransform.position, destinationTransform.position);
        const travelingDuration = distance > 0.001 ? (distance / speed) : 0.5;
        const stepCount         = travelingDuration * intervalFrequency;
        const stepInterval      = 1 / stepCount;

        let step                = 0.0;
        let currentPosition     = vec3.create();
        let currentOrientation  = quat.create();

        if(this.interval)
        {
            clearInterval(this.interval);
        }

        return new Promise(resolve =>
        {
            this.interval = setInterval(
                () =>
                {
                    step        += stepInterval;
                    const alpha = Math.min(this.smoothStep(step), 1.0);

                    vec3.lerp(currentPosition, currentCameraTransform.position, destinationTransform.position, alpha);
                    quat.slerp(currentOrientation, currentCameraTransform.orientation, destinationTransform.orientation, alpha);

                    this.setGlobalTransform(
                    {
                        position    : Array.from(currentPosition),
                        orientation : Array.from(currentOrientation)
                    });

                    if(alpha >= 1.0)
                    {
                        this.stopTravel();
                        resolve();

                        // Dirty fix to reset the orbit controller distance with the look at point.
                        const controllerType = this.getControllerType();
                        if(controllerType !== controller_type.orbit)
                        {
                            return;
                        }

                        setTimeout(() =>
                        {
                            this.setControllerType(controllerType);
                        }, 100);
                    }
                },
                1000 / intervalFrequency
            );
        });
    }

    //--------------------------------------------------------------------------
    /**
     * Animate camera traveling from current position to specified client's camera.
     * The client camera used is the first camera returned by [getClientCameras]{@link SDK3DVerse.engineAPI.cameraAPI#getClientCameras}.
     * Inputs are disabled during travel.
     * 
     * @param {string} clientUUID UUID of client being traveled to
     * @param {number} speed Travel speed. Duration of travel is equal to distance / speed
     * 
     * @see [getClientUUIDs]{@link SDK3DVerse#getClientUUIDs}
     * 
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * const currentClientUUID = SDK3DVerse.getClientUUID();
     * const otherClientUUID = SDK3DVerse.getClientUUIDs().find(uuid => uuid !== currentClientUUID);
     * await viewport.travelToClient(otherClientUUID, 10);
     * @fires onEntitiesUpdated
     * 
     * @async
     */
    travelToClient(clientUUID, speed)
    {
        if(!this.engineAPI.cameraAPI.clientViewportsMap.hasOwnProperty(clientUUID))
        {
            console.warn('Cannot find the clientUUID ' + clientUUID + ' in teleport');
            return;
        }

        const [{transform}] = this.engineAPI.cameraAPI.clientViewportsMap[clientUUID];

        if(!transform)
        {
            console.error('No viewport transform for client ' + clientUUID);
            return;
        }

        return this.travel(
            transform.position,
            transform.orientation,
            speed
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Stop the camera mid-travel and re-enable inputs. This can be used after a call
     * to [travel]{@link Viewport#travel}, or any other functions that call travel
     * such as [focusOn]{@link Viewport#focusOn} or [smoothLookAt]{@link Viewport#smoothLookAt}.
     */
    stopTravel()
    {
        if(!this.interval)
        {
            return;
        }

        clearInterval(this.interval);
        this.interval = null;
        this.engineAPI.cameraAPI.streamer.inputRelay.resumeInputs();
    }

    //--------------------------------------------------------------------------
    /**
     * Focus on the entity. Uses [travel]{@link Viewport#travel}
     * to animate the camera traveling from a starting position and orientation to the entity.
     *
     * @param {Entity} entity
     * @param {object} [options = {}] Travel animation options
     * @param {SDK_Vec3} [options.startPosition] Initial position of camera, defaults to its current position
     * @param {SDK_Quat} [options.startOrientation] Initial orientation of camera, defaults to its current orientation
     * @param {float} [options.speedFactor=4] Travel animation speed
     * @param {float} [options.distanceShift=0] Distance before entity to stop camera animation
     *
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * viewport.focusOn(entity, { speedFactor : 10 });
     *
     * @fires onEntitiesUpdated
     * 
     * @async
     */
    focusOn(entity, options = {})
    {
        const {
            startPosition,
            startOrientation,
            speedFactor = 4,
            distanceShift = 0
        } = options;

        if(!entity.isAttached("local_transform"))
        {
            return;
        }

        const aabb = entity.getGlobalAABB();
        if(!aabb)
        {
            return;
        }

        // Only the support perspective mode
        if(this.projectionType != 0)
        {
            return;
        }

        const cameraPosition    = startPosition || this.getTransform().position;
        const fov               = this.getProjection().fovy;

        const {
            targetPosition,
            targetOrientation
        }                       = SDK3DVerse_Utils.lookAtAABB(aabb, cameraPosition, fov, distanceShift);
        const speed             = vec3.distance(targetPosition, cameraPosition) * speedFactor;
        if(speed === 0)
        {
            return;
        }

        return this.engineAPI.cameraAPI.travel(
            this,
            targetPosition,
            targetOrientation,
            speed,
            startPosition,
            startOrientation
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Set the global orientation of the camera to make it look at the target position.
     *
     * @param {SDK_Vec3} targetPosition - Position to look at in world space
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * viewport.lookAt([0, 0, 0]);
     * 
     * @fires onEntitiesUpdated
     * 
     */
    lookAt(targetPosition, propagateChanges = true, updateMatrices = false)
    {
        const globalPosition        = this.getTransform().position;
        const { targetOrientation } = this.computeOrientationToTarget(targetPosition);

        this.setGlobalTransform(
        {
            position    : globalPosition,
            orientation : targetOrientation
        }, propagateChanges, updateMatrices);
    }

    //--------------------------------------------------------------------------
    /**
     * Same as [Viewport.lookAt]{@link Viewport#lookAt} but
     * with a travelling animation of the camera using [travel]{@link Viewport#travel}.
     *
     * @param {SDK_Vec3} targetPosition - Position to look at in world space
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * viewport.smoothLookAt([0, 0, 0]);
     * 
     * @fires onEntitiesUpdated
     */
    async smoothLookAt(targetPosition, currentLookAtPoint = Viewport.lookAtPoint)
    {
        const globalPosition    = this.getTransform().position;
        const { direction, targetOrientation: newDirection } = this.computeOrientationToTarget(targetPosition);

        const currentDistance   = vec3.distance(globalPosition, currentLookAtPoint);
        const newPosition       = vec3.create();
        vec3.scaleAndAdd(newPosition, targetPosition, direction, currentDistance);

        const speed             = vec3.distance(newPosition, globalPosition);

        await SDK3DVerse.engineAPI.cameraAPI.travel(
            this,
            newPosition,
            newDirection,
            speed
        );

        return { newPosition, newDirection };
    }

    //--------------------------------------------------------------------------
    /**
     * Get the [camera controller type]{@link SDK3DVerse#cameraControllerType} of the camera. If the camera doesn't
     * have a controller, `null` is returned.
     * 
     * @returns {SDK3DVerse.cameraControllerType|null}
     * 
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * const controllerType = viewport.getControllerType();
     */
    getControllerType()
    {
        return this.cameraEntity
                ? (this.cameraEntity.controller ? this.cameraEntity.controller.type : null)
                : null;
    }

    //--------------------------------------------------------------------------
    /**
     * Set the camera controller type of the camera.
     * @param {SDK3DVerse.cameraControllerType} controllerType The [camera controller type]{@link SDK3DVerse#cameraControllerType}
     * 
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     * viewport.setControllerType(SDK3DVerse.cameraControllerType.orbit);
     */
    setControllerType(controllerType)
    {
        if(this.cameraEntity && this.cameraEntity.hasOwnProperty("controller"))
        {
            this.engineAPI.cameraAPI.deleteController(this.cameraEntity.controller);

            const isEnabled = this.id === this.engineAPI.cameraAPI.currentViewportEnabled?.id;
            this.cameraEntity.controller = this.engineAPI.cameraAPI.createController(controllerType, this.cameraEntity, isEnabled);
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the lens component of the camera, e.g.
     * [perspective_lens component]{@link http://localhost:5000/tutorial-components_perspective_lens}.
     *
     * @returns {object} The lens component value.
     */
    getProjection()
    {
        if(!this.cameraEntity)
        {
            return {};
        }

        const lensComponentName = this.projectionType === projection_type.perspective
                                ? 'perspective_lens'
                                : 'orthographic_lens';

        return this.cameraEntity.UNSAFE_getComponent(lensComponentName);
    }

    //--------------------------------------------------------------------------
    /**
     * Get the projection matrix of the camera, which transforms coordinates from view space
     * (or camera space) to clip space.
     * 
     * Clip space is represented by a cuboid with [-1;1] dimensions for every axis and used
     * for clipping vertices: all vertices inside this volume will be rendered on the screen.
     * In this space, the Z coordinate of each vertex specifies how far away a vertex is from the screen.
     *
     * @returns {mat4} The projection matrix.
     */
    getProjectionMatrix()
    {
        return this.projectionMatrix;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the view projection matrix of the camera, which transforms coordinates from world space to clip space.
     * 
     * The view projection matrix is the product of the the view matrix and the projection matrix.
     * The view matrix transforms coordinates from world space to view space (or camera space), and is equal to
     * the inverse camera world matrix i.e. the inverse of [getWorldMatrix]{@link Viewport#getWorldMatrix}.
     * For more info on the projection matrix see [getProjectionMatrix]{@link Viewport#getProjectionMatrix}.
     *
     * @returns {mat4} The view projection matrix.
     */
    getViewProjectionMatrix()
    {
        return this.viewProjectionMatrix;
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeScreenSpaceToWorldSpaceMatrix()
    {
        const screenSpaceToWorldSpaceMatrix = mat4.create();

        mat4.invert(
            screenSpaceToWorldSpaceMatrix,
            this.getViewProjectionMatrix()
        );

        return screenSpaceToWorldSpaceMatrix;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the viewport area size within the canvas.
     *
     * @returns {SDK_Vec2_uint} `[width, height]` in pixels.
     */
    getAreaSize(newWidth, newHeight)
    {
        const decoder       = SDK3DVerse.streamer.decoder;
        const targetWidth   = this.widthRatio  * (newWidth  || decoder.canvasWidth);
        const targetHeight  = this.heightRatio * (newHeight || decoder.canvasHeight);

        return [targetWidth, targetHeight];
    }

    //--------------------------------------------------------------------------
    /**
     * Set viewport area size within the canvas.
     *
     * @param {uint} width - Width in pixels
     * @param {uint} height - Height in pixels
     * @param {boolean} [triggerViewportUpdate=true] - Update the viewport to make the rendering service
     * adapt it immediately. Set to false to avoid unnecessary updates
     */
    setAreaSize(width, height, triggerViewportUpdate = true)
    {
        this.setAreaRatio(
            width / SDK3DVerse.streamer.decoder.canvasWidth,
            height / SDK3DVerse.streamer.decoder.canvasHeight,
            triggerViewportUpdate
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Set viewport area ratio within the canvas. For example, widthRatio=0.5
     * and heightRatio=0.5 means the viewport takes 1/4 of the canvas.
     *
     * @param {float} widthRatio - The width ratio normalized into the 0.0 to 1.0 range
     * @param {float} heightRatio - The height ratio normalized into the 0.0 to 1.0 range
     * @param {boolean} [triggerViewportUpdate=true] - Update the viewport to make the rendering service
     * adapt it immediately. Set to false to avoid unnecessary updates
     */
    setAreaRatio(widthRatio, heightRatio, triggerViewportUpdate = true)
    {
        this.widthRatio     = widthRatio;
        this.heightRatio    = heightRatio;

        this.computeProjectionData();
        this.updateProjectionMatrix();

        if(triggerViewportUpdate)
        {
            this.onViewportUpdate();
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the viewport offset within the canvas.
     *
     * @returns {SDK_Vec2_uint} `[left, top]` in pixels.
     */
    getOffset()
    {
        return [
            this.leftRatio * SDK3DVerse.streamer.decoder.canvasWidth,
            this.topRatio * SDK3DVerse.streamer.decoder.canvasHeight,
        ];
    }

    //--------------------------------------------------------------------------
    /**
     * Set viewport offset within the canvas.
     *
     * @param {uint} left - Left offset in pixels
     * @param {uint} top - Top offset in pixels
     * @param {boolean} [triggerViewportUpdate=true] - Update the viewport to make the rendering service
     * adapt it immediately. Set to false to avoid unnecessary updates
     */
    setOffset(left, top, triggerViewportUpdate = true)
    {
        this.setOffsetRatio(
            left / SDK3DVerse.streamer.decoder.canvasWidth,
            top / SDK3DVerse.streamer.decoder.canvasHeight,
            triggerViewportUpdate
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Set viewport offset ratio within the canvas. For example, leftRatio=0.5
     * and topRatio=0.5 means the top left of the viewport is at the center of the canvas.
     *
     * @param {float} leftRatio - Left offset ratio normalized into the 0.0 to 1.0 range
     * @param {float} topRatio - Top offset ratio normalized into the 0.0 to 1.0 range
     * @param {boolean} [triggerViewportUpdate=true] - Update the viewport to make the rendering service
     * adapt it immediately. Set to false to avoid unnecessary updates
     */
    setOffsetRatio(leftRatio, topRatio, triggerViewportUpdate = true)
    {
        this.leftRatio  = leftRatio;
        this.topRatio   = topRatio;

        if(triggerViewportUpdate)
        {
            this.onViewportUpdate();
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Check if a position within the canvas is within the viewport area.
     * It can be used to determine the hovered viewports, as shown in the example below.
     *
     * @param {SDK_Vec2_uint} position - The canvas position in pixels
     *
     * @returns {boolean} True if the position is within viewport area.
     * @example
     * const canvas = document.getElementById("display_canvas");
     * canvas.addEventListener("mousedown", (event) => {
     *     const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewportById(0);
     *     const position = [event.offsetX, event.offsetY];
     *     console.log("position clicked in canvas:", position);
     *     console.log("clicked inside viewport of id 0?", viewport.isInArea(position));
     * });
     */
    isInArea(position)
    {
        const areaSize = this.getAreaSize();
        const offset   = this.getOffset();

        return position[0] >= offset[0] && position[0] <= (offset[0] + areaSize[0])
            && position[1] >= offset[1] && position[1] <= (offset[1] + areaSize[1]);
    }

    //--------------------------------------------------------------------------
    /**
     * Reset the camera to its default transform.
     *
     * @param {uint} [travelingDurationInSec=0] - If superior to zero, then animate a camera traveling
     * to its default transform for the specified duration using [travel]{@link Viewport#travel}
     * 
     * @fires onEntitiesUpdated
     *
     * @async
     */
    async resetTransform(travelingDurationInSec = 0)
    {
        await this.reset(true, travelingDurationInSec);
    }

    //--------------------------------------------------------------------------
    // private
    async reset(propagateChanges = true, travelingDurationInSec = 0)
    {
        const cameraAPI = SDK3DVerse.engineAPI.cameraAPI;

        let defaultValue;
        if(this.defaultTransform)
        {
            defaultValue = this.defaultTransform;
        }
        else if(SDK3DVerse.engineAPI.editorAPI.sceneSettings.hasOwnProperty("default_camera_transform"))
        {
            defaultValue = SDK3DVerse_Utils.clone(SDK3DVerse.engineAPI.editorAPI.sceneSettings.default_camera_transform);
        }
        else
        {
            defaultValue = SDK3DVerse_Utils.clone(SDK3DVerse_Utils.getIdentityTransform());
        }

        if(travelingDurationInSec > 0)
        {
            const speed = vec3.distance(defaultValue.position, this.getTransform().position) / travelingDurationInSec;
            if(speed === 0)
            {
                return;
            }

            await cameraAPI.travel(
                this,
                defaultValue.position,
                defaultValue.orientation,
                speed
            );

            if(cameraAPI.getControllerType(this.id) === controller_type.orbit)
            {
                // do not run under HACK to recreate orbit controller since
                // it has been done in the travel
                return;
            }
        }
        else
        {
            this.setTransform(defaultValue, propagateChanges);
        }

        // HACK: Need to wait a few milliseconds for the camera transform to propagate to the rendering service
        //       before recreating the controller for it to get the reset camera transform.
        setTimeout(() =>
        {
            const currentControllerType   = cameraAPI.getControllerType(this.id);

            // recreate the camera controller with the same type to reset its internal transformation properties
            cameraAPI.setControllerType(this.id, currentControllerType);
        }, 100);
    }

    //--------------------------------------------------------------------------
    /**
     * Project a position in world space to a point in the viewport screen space.
     *
     * @param {SDK_Vec3} position - Position in world space
     *
     * @returns {SDK_Vec3} Projected point in the viewport screen space. 
     * `[0, 0]` = Top left, `[canvas width, canvas height]` = Bottom right, `[0, canvas height]` = Bottom left, `[canvas width, 0]` = Top right.
     */
    project(position)
    {
         const [viewportWidth, viewportHeight]   = this.getAreaSize();
         const projectedPosition                 = vec3.create();

         vec3.transformMat4(
             projectedPosition,
             vec3.fromValues(...position),
             this.getViewProjectionMatrix()
         );

         return [
             (projectedPosition[0] + 1) * viewportWidth  / 2,
             (-projectedPosition[1] + 1) * viewportHeight / 2,
             projectedPosition[2]
         ];
    }

    //--------------------------------------------------------------------------
    /**
     * Project a position in the viewport screen space to a point in world space.
     *
     * @param {SDK_Vec2_uint} screenCoordinate - Position in viewport screen space. `[0, 0]` = Top left, `[canvas width, canvas height]` = Bottom right, `[0, canvas height]` = Bottom left, `[canvas width, 0]` = Top right
     * @param {float} normalizedDepth - Depth between the near and far planes of the camera frustum, normalized in the [0.0, 1.0] range
     * @returns {SDK_Vec3} Projected point in world space at a distance equal to `normalizedDepth`.
     */
    unproject(screenCoordinate, normalizedDepth)
    {
        const normalizedPosition    = this.computeNormalizedPositionInViewport(screenCoordinate);
        const worldPosition         = vec3.create();

        vec3.transformMat4(
            worldPosition,
            vec3.fromValues(normalizedPosition[0], normalizedPosition[1], normalizedDepth),
            this.computeScreenSpaceToWorldSpaceMatrix()
        );

        return Array.from(worldPosition);
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeRayFromScreenCoordinates(screenCoordinate)
    {
        const normalizedPosition = this.computeNormalizedPositionInViewport(screenCoordinate);

        const ray = {
            origin      : vec3.fromValues(...this.getTransform().position),
            direction   : vec3.create(),
        };

        vec3.transformMat4(
            ray.direction,
            vec3.fromValues(normalizedPosition[0], normalizedPosition[1], 0.5),
            this.computeScreenSpaceToWorldSpaceMatrix()
        );

        vec3.sub(
            ray.direction,
            ray.direction,
            ray.origin
        );

        vec3.normalize(ray.direction, ray.direction);
        return ray;
    }

    //--------------------------------------------------------------------------
    /** @private */
    computeNormalizedPositionInViewport = (position) =>
    {
        const areaSize = this.getAreaSize();
        const offset   = this.getOffset();

        return [
            (position[0] - offset[0]) / areaSize[0] * 2 - 1,
            -(position[1] - offset[1]) / areaSize[1] * 2 + 1
        ];
    }

    //--------------------------------------------------------------------------
    /** @private */
    enableUpdateFromFrameData()
    {
        this.disableUpdate = false;
    }

    //--------------------------------------------------------------------------
    /** @private */
    disableUpdateFromFrameData()
    {
        this.disableUpdate = true;
    }

    //--------------------------------------------------------------------------
    /** @private */
    smoothStep(x)
    {
        return ((x) * (x) * (x) * ((x) * ((x) * 6 - 15) + 10));
    }
}

//------------------------------------------------------------------------------
/**
 * @ignore
 */
class SDK3DVerse_CameraAPI
{
    static MAX_VIEWPORT = 8;

    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(notifier, engineAPI, streamer)
    {
        this.notifier               = notifier;
        this.engineAPI              = engineAPI;
        this.streamer               = streamer;

        this.viewportRequestQueue   = async.queue(
            (request, callback) =>
            {
                this.requestRegisterViewports(request.viewports)
                .then(() =>
                {
                    callback();
                })
                .catch((error) =>
                {
                    callback(error);
                })
            },
            1
        );

        this.resetState();
    }

    //--------------------------------------------------------------------------
    initialize()
    {
        this.notifier.on('onFrameDataReceived', this.onFrameDataReceived);
        this.notifier.on('onEntitiesDeleted', this.onEntitiesDeleted);
        this.notifier.on('onNewRenderingAreaSize', this.updateViewportsProjection);
        this.notifier.on('onCanvasResized', this.computeCanvasClientRect);
    }

    //--------------------------------------------------------------------------
    resetState()
    {
        this.globalControllerID     = 0;
        this.isEditorConnected      = false;
        this.currentClientUUID      = null;
        this.defaultCameras         = [];
        this.controllerSettings     = {
            speed          : 4.0,
            sensitivity    : 0.1,
            damping        : 0.65,
            angularDamping : 0.65
        };

        this.defaultCameraTemplate  = {
            local_transform: SDK3DVerse_Utils.getIdentityTransform(),
            camera :
            {
                renderGraphRef : DEFAULT_RENDER_GRAPH
            },
            perspective_lens :
            {
                aspectRatio : 1.777,
                fovy        : 60.0,
                nearPlane   : 0.01,
                farPlane    : 0.0
            }
        };

        this.clientViewportsMap     = {};
        this.viewports              = [];
        this.registeredViewports    = [];
        this.currentViewportEnabled = null;
        this.canvasClientRect       = null;

        for(let i = 0; i < SDK3DVerse_CameraAPI.MAX_VIEWPORT; ++i)
        {
            this.viewports.push(new Viewport(i, this.updateViewports, this.engineAPI));
        }
    }

    //--------------------------------------------------------------------------
    // private
    setupDisplay(canvas)
    {
        if(this.canvas)
        {
            this.cameraMouseDownListener && this.canvas.removeEventListener('mousedown', this.cameraMouseDownListener, false);
            this.cameraMouseUpListener && this.canvas.removeEventListener('mouseup', this.cameraMouseUpListener, false);
        }

        this.canvas = canvas;

        if(this.canvas)
        {
            this.cameraMouseDownListener = this.onMouseEvent('mousedown', (hoveredViewport) =>
            {
                this.setActiveViewport(hoveredViewport);
                this.notifier.emit('onViewportActivated', this.currentViewportEnabled);
            });

            this.cameraMouseUpListener = this.onMouseEvent('mouseup', () =>
            {
                this.notifier.emit('onViewportSelected', this.currentViewportEnabled);
            });

            this.computeCanvasClientRect();
        }
    }

    //--------------------------------------------------------------------------
    // private
    computeCanvasClientRect = () =>
    {
        this.canvasClientRect = this.canvas.getClientRects()[0];
    };

    //--------------------------------------------------------------------------
    // private
    onMouseEventListener = (event, listener) =>
    {
        const clickedPosition = this.computeMousePositionInCanvas(event);
        const hoveredViewport = this.getHoveredViewport(clickedPosition);
        if(hoveredViewport)
        {
            return listener(hoveredViewport, event);
        }
    };

    //--------------------------------------------------------------------------
    // private
    computeMousePositionInViewport = (event, viewport) =>
    {
        const position = this.computeMousePositionInCanvas(event);
        return viewport.computeNormalizedPositionInViewport(position);
    };

    //--------------------------------------------------------------------------
    // private
    computeMousePositionInCanvas = (event) =>
    {
        const pointer = event.changedTouches ? event.changedTouches[ 0 ] : event;
        return this.computeLocalPositionInCanvas(pointer.clientX, pointer.clientY);
    };

    //--------------------------------------------------------------------------
    // private
    computeLocalPositionInCanvas = (x, y) =>
    {
        return [
            x - this.canvasClientRect.left,
            y - this.canvasClientRect.top
        ];
    };

    //--------------------------------------------------------------------------
    /**
     * Register the listener function on the canvas to the [MouseEvent]{@link https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent} named `eventName`.
     * When the event is raised, the listener function receives the {@link Viewport} object along with the event.
     *
     * @param {string} eventName The name of the event (e.g. click, dblclick, mouseup, mousedown)
     * @param {ViewportMouseEventListener} listener The listener function
     * 
     * @example
     * SDK3DVerse.engineAPI.cameraAPI.onMouseEvent('dblclick', (viewport, event) =>
     * {
     *      // do something with viewport, event
     * });
     *
     * @method SDK3DVerse.engineAPI.cameraAPI#onMouseEvent
     */
    onMouseEvent(eventName, listener)
    {
        const cameraListener = (event) => this.onMouseEventListener(event, listener);
        this.canvas.addEventListener(eventName, cameraListener, false);

        return cameraListener;
    }

    //--------------------------------------------------------------------------
    // private
    getHoveredViewport(mousePosition)
    {
        for (let i = this.registeredViewports.length - 1; i >= 0; i--)
        {
            const viewport = this.registeredViewports[i];

            if(viewport.isInArea(mousePosition))
            {
                return viewport;
            }
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Update the controller settings (e.g. speed, sensitivity) of the cameras
     * of all viewports.
     *
     * @param {Partial<CameraControllerSettings>} controllerSettings A partial object containing camera controller settings
     * 
     * @example
     * SDK3DVerse.engineAPI.cameraAPI.updateControllerSettings({ sensitivity : 0.1 });
     * 
     * @method SDK3DVerse.engineAPI.cameraAPI#updateControllerSettings
     */
    updateControllerSettings(config)
    {
        if(config.hasOwnProperty("lookAtPoint"))
        {
            Viewport.lookAtPoint = config.lookAtPoint;
        }

        this.controllerSettings = {
            ...this.controllerSettings,
            ...config
        };

        // Orthographic viewport speed need to depend on zoomFactor for its left/right/up/down moves
        const viewport = this.engineAPI.cameraAPI.currentViewportEnabled;
        let { speed }  = config;
        if(typeof speed === 'number' && viewport && viewport.getProjectionType() === projection_type.orthographic)
        {
            const zoomFactor = viewport.getZoomFactor();
            config.speed = zoomFactor ? speed * zoomFactor : speed;
        }

        this.engineAPI.ftlAPI.updateControllerSettings(config);
    }

    //--------------------------------------------------------------------------
    // private
    setActiveViewport(selectedViewport)
    {
        const controllersState  = [];
        const viewportIsChanged = this.currentViewportEnabled != selectedViewport;
        if(this.currentViewportEnabled != null && viewportIsChanged && this.currentViewportEnabled.cameraEntity.controller)
        {
            controllersState.push({
                id      : this.currentViewportEnabled.cameraEntity.controller.id,
                enabled : false
            });
        }

        if(selectedViewport && selectedViewport.cameraEntity && selectedViewport.cameraEntity.controller)
        {
            controllersState.push({
                id      : selectedViewport.cameraEntity.controller.id,
                enabled : true
            });
            this.currentViewportEnabled = selectedViewport;
        }
        else
        {
            this.currentViewportEnabled = null;
        }

        this.engineAPI.ftlAPI.setControllersState(controllersState);

        if(viewportIsChanged)
        {
            this.notifier.emit('onViewportActivated', this.currentViewportEnabled);

            // Refresh controller speed setting because its affected by the viewport zoomFactor
            this.updateControllerSettings({ speed: this.controllerSettings.speed });
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the viewport with the specified id.
     *
     * @param {uint} viewportId The viewport id
     *
     * @returns {Viewport} The viewport.
     * 
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewportById(0);
     *
     * @method SDK3DVerse.engineAPI.cameraAPI#getViewportById
     */
    getViewportById(id)
    {
        const viewport = this.viewports.find((viewport) => viewport.id == id);
        if(!viewport)
        {
            throw "Invalid viewport ID : " + id;
        }

        return viewport;
    }

    //--------------------------------------------------------------------------
    // deprecated
    getViewportByID(id)
    {
        return this.getViewportById(id);
    }

    //--------------------------------------------------------------------------
    // private
    registerViewport(id)
    {
        const viewport = this.getViewportById(id);

        this.registeredViewports.push(viewport);

        return viewport;
    }

    //--------------------------------------------------------------------------
    // private
    getActiveViewports()
    {
        return this.registeredViewports;
    }

    //--------------------------------------------------------------------------
    /**
     * Get viewports. These viewports were previously set by a call to one functions listed below.
     * 
     * @see [setViewports]{@link SDK3DVerse.engineAPI.cameraAPI#setViewports}
     * @see [setMainCamera]{@link SDK3DVerse.engineAPI.cameraAPI#setMainCamera}
     * @see [startSession]{@link SDK3DVerse#startSession}
     * @see [joinSession]{@link SDK3DVerse#joinSession}
     * @see [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}
     * 
     * @returns {Array.<Viewport>} Array of viewports.
     * 
     * @example
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewports()[0];
     *
     * @method SDK3DVerse.engineAPI.cameraAPI#getViewports
     */
    getViewports()
    {
        return this.registeredViewports;
    }

    //--------------------------------------------------------------------------
    setDefaultCameraTemplateValues(dataJSON)
    {
        this.defaultCameraTemplate.camera.dataJSON = dataJSON;
    }

    //--------------------------------------------------------------------------
    // private
    async onEditorConnected()
    {
        const defaultCameraComponents = {};
        SDK3DVerse_Utils.resolveComponentDependencies(defaultCameraComponents, "perspective_lens");

        const { camera }        = defaultCameraComponents;
        camera.renderGraphRef   = DEFAULT_RENDER_GRAPH;
        camera.dataJSON         = this.defaultCameraTemplate.camera.dataJSON || camera.dataJSON;

        this.defaultCameraTemplate = defaultCameraComponents;

        this.isEditorConnected = true;

        // If a timeout is running clear it, and registerViewports immediately.
        if(this.editorTimeout)
        {
            clearTimeout(this.editorTimeout);
            if(this.viewportsTemplate)
            {
                await this.registerViewports(this.viewportsTemplate);
            }

            this.viewportUpdatedCallback();
        }
        // Otherwise we already timed out, and we have to re-create cameras from the editor.
        else if(this.isOffline)
        {
            this.createCamerasFromEditor();
        }
    }

    //--------------------------------------------------------------------------
    // private
    async createCamerasFromEditor()
    {
        // Do not disturb the async offline viewport register.
        await this.offlineRegisterPromise;

        // Delete all defaults cameras, we're going to recreate it.
        this.defaultCameras     = [];

        const activeViewports   = this.getActiveViewports();
        const oldCameras        = activeViewports.map((viewport) => viewport.getCamera());
        const oldCameraRTIDs    = oldCameras.map((camera) => camera.UNSAFE_getComponent("euid").rtid);

        // Create a new camera for each active viewports
        for(const viewport of activeViewports)
        {
            const viewportCamera             = viewport.getCamera();
            const controllerType             = viewportCamera.controller ? viewportCamera.controller.type : controller_type.none;

            // Copy transform, camera, and perspective lens components only if those were specified in the viewport
            // template config. Otherwise we rely on createOrGetDefaultCamera inner logic, for example:
            //      the default_camera_transform & default_camera_component scene settings.
            const viewportProperties         = this.viewportsTemplate ? this.viewportsTemplate.find(v => v.id === viewport.id) : null;
            let defaultTransform             = null;
            let defaultCameraValues          = null;
            let defaultPerspectiveLensValues = null;
            if(viewportProperties.defaultTransform)
            {
                defaultTransform             = viewport.getTransform();
            }
            if(viewportProperties.defaultCameraValues)
            {
                defaultCameraValues          = viewportCamera.getComponent('camera');
            }
            if(viewportProperties.defaultPerspectiveLensValues)
            {
                defaultPerspectiveLensValues = viewportCamera.isAttached('perspective_lens')
                                               ? viewportCamera.getComponent('perspective_lens')
                                               : null;
            }

            const camera = await this.createOrGetDefaultCamera(
                viewport,
                defaultTransform,
                controllerType,
                defaultCameraValues,
                defaultPerspectiveLensValues,
                null,
                false
            );
            viewport.setCamera(camera);

            // To avoid glitches during camera switches between offline and online mode
            // we need to write the frameTransform with the requested transform above.
            viewport.frameTransform = null;
            viewport.setFrameTransform(camera);
        }

        this.updateViewports();

        if(this.viewportsTemplate)
        {
            for(const viewportProperties of this.viewportsTemplate)
            {
                if(viewportProperties.onCameraCreation)
                {
                    viewportProperties.onCameraCreation(this.getViewportById(viewportProperties.id).getCamera());
                }
            }
        }

        for(const oldCamera of oldCameras)
        {
            if(oldCamera.controller)
            {
                this.deleteController(oldCamera.controller);
            }
        }

        // Delete previous camera
        this.engineAPI.ftlAPI.deleteEntities(oldCameraRTIDs);

        this.isOffline = false;
    }

    //--------------------------------------------------------------------------
    // private
    setEditorTimeout(editorTimeoutMilliseconds)
    {
        this.editorTimeoutMilliseconds = editorTimeoutMilliseconds;
    }

    //--------------------------------------------------------------------------
    /**
     * Set all viewports that are drawing to the canvas. If a viewport with id specified in {@link ViewportInfo} already exists,
     * then updates the properties of the {@link Viewport} object. Otherwise, a new {@link Viewport} is created.
     *
     * @param {Array.<ViewportInfo>} viewports - Array of {@link ViewportInfo} objects
     *
     * @example
     * const viewportInfo = {
     *      id: 0,
     *      width: 1.0,
     *      height: 1.0,
     *      left: 0.0,
     *      top: 0.0,
     *      defaultControllerType: SDK3DVerse.cameraControllerType.editor,
     *      onCameraCreation: (cameraEntity) => { console.log(cameraEntity.getName(), "was created") }
     * };
     * // This will create a viewport that draws over the entire canvas.
     * // A default camera is also created, with an 'editor' camera controller.
     * // This camera is associated to the viewport, and is a transient entity. Transient entities only exist for the duration of the session.
     * await SDK3DVerse.engineAPI.cameraAPI.setViewports([viewportInfo]);
     * const viewport = SDK3DVerse.engineAPI.cameraAPI.getViewportById(viewportInfo.id);
     * 
     * @async
     * 
     * @method SDK3DVerse.engineAPI.cameraAPI#setViewports
     */
    setViewports(viewports)
    {
        return new Promise((resolve, reject) =>
        {
            this.viewportRequestQueue.push({viewports : viewports}, (err) =>
            {
                if(err)
                {
                    reject(err);
                }
                else
                {
                    resolve();
                }
            });
        })
    }

    //--------------------------------------------------------------------------
    /**
     * Setup a single viewport with an existing camera entity. 
     * 
     * The viewport will be given a viewport id of 0, and draw over the entire canvas. This is a helper
     * function that calls [setViewports]{@link SDK3DVerse.engineAPI.cameraAPI#setViewports} for you.
     *
     * @param {Entity} cameraEntity - Entity that contains a camera component
     * 
     * @example
     * await SDK3DVerse.engineAPI.cameraAPI.setMainCamera(cameraEntity);
     * 
     * @async
     *
     * @method SDK3DVerse.engineAPI.cameraAPI#setMainCamera
     */
    async setMainCamera(cameraEntity)
    {
        if(!cameraEntity.isAttached('camera'))
        {
            console.warn('Entity does not have a camera component');
            return;
        }

        const viewports = [
            {
                id: 0,
                left: 0,
                top: 0,
                width: 1,
                height: 1,
                camera: cameraEntity,
            },
        ];
        await this.setViewports(viewports);
    }

    //--------------------------------------------------------------------------
    // private
    async requestRegisterViewports(viewportProperties)
    {
        this.viewportsTemplate = viewportProperties || [];

        if(viewportProperties === null)
        {
            this.registeredViewports = [];
            this.updateViewports();
        }

        if(!this.isEditorConnected)
        {
            let isOffline = true;

            if(this.isOffline)
            {
                await this.registerViewports(this.viewportsTemplate, isOffline);
            }
            else if(!this.editorTimeout)
            {
                const viewportUpdatedPromise = new Promise((resolve) =>
                {
                    this.viewportUpdatedCallback = resolve;
                });

                this.editorTimeout = setTimeout(
                    async () =>
                    {
                        this.editorTimeout  = null;
                        this.isOffline      = true;
                        this.offlineRegisterPromise = this.registerViewports(this.viewportsTemplate, isOffline);

                        await this.offlineRegisterPromise;
                        this.viewportUpdatedCallback();
                    },
                    this.editorTimeoutMilliseconds
                );

                await viewportUpdatedPromise;
            }
        }
        else
        {
            await this.registerViewports(this.viewportsTemplate);
        }
    }

    //--------------------------------------------------------------------------
    // private
    async registerViewports(viewportsProperties, isOffline = false)
    {
        const removedViewports = this.registeredViewports.filter(v =>
            viewportsProperties.findIndex(vp => v.id === vp.id) === -1
        );

        this.registeredViewports = [];
        for(const viewportProperties of viewportsProperties)
        {
            const {
                id,
                defaultControllerType,
                defaultTransform,
                defaultCameraValues,
                defaultPerspectiveLensValues,
                camera: attachedCamera
            } = viewportProperties;
            const registeredViewport = this.registerViewport(id);
            registeredViewport.setProperties(viewportProperties);

            if(defaultTransform)
            {
                viewportProperties.defaultTransform = SDK3DVerse_Utils.patchTransform(defaultTransform, false);
                registeredViewport.defaultTransform = viewportProperties.defaultTransform;
            }

            const doesDefaultCameraExists   = Boolean(this.defaultCameras[id]);
            const createCamera              = !attachedCamera || !attachedCamera.isExternal();

            let camera;
            if(createCamera)
            {
                // There may be an attachedCamera which is not an external entity.
                // If there's one, then the created or get default camera will be a child entity of attachedCamera.
                camera = await this.createOrGetDefaultCamera(
                    registeredViewport,
                    defaultTransform,
                    defaultControllerType || controller_type.fps,
                    defaultCameraValues,
                    defaultPerspectiveLensValues,
                    attachedCamera || null,
                    isOffline
                );
            }
            else if(!isOffline)
            {
                // There's an attachedCamera and it's an external entity.
                // Default camera and perspective lens components are applied.
                camera                     = attachedCamera;
                const cameraComponent      = camera.getComponent('camera');
                const isMissingRenderGraph = cameraComponent.renderGraphRef === SDK3DVerse_Utils.invalidUUID;

                if(defaultCameraValues || isMissingRenderGraph)
                {
                    await this.engineAPI.setOrCreateOverrider(camera, 'camera', defaultCameraValues || this.getDefaultCameraValues(false), true);
                }
                if(registeredViewport.hasPerspectiveProjection() && defaultPerspectiveLensValues)
                {
                    await this.engineAPI.setOrCreateOverrider(camera, 'perspective_lens', defaultPerspectiveLensValues, true);
                }
            }

            registeredViewport.setFrameTransform(camera);
            registeredViewport.setCamera(camera);

            if(createCamera && !doesDefaultCameraExists && viewportProperties.onCameraCreation)
            {
                viewportProperties.onCameraCreation(camera);
            }
        }

        if(!isOffline)
        {
            this.commitCameraChanges();
        }
        this.updateViewports();

        if(removedViewports.length > 0)
        {
            this.releaseViewports(removedViewports, isOffline);
        }
    }

    //--------------------------------------------------------------------------
    // private
    updateViewports = () =>
    {
        // TODO : Make sure the viewports config has changed before sending it to the renderer
        this.streamer.updateViewports(this.registeredViewports);
        this.notifier.emit('onViewportsUpdated', this.registeredViewports);

        if(this.currentViewportEnabled && !this.registeredViewports.find(v => v.id === this.currentViewportEnabled.id))
        {
            this.setActiveViewport(null);
        }
    }

    //--------------------------------------------------------------------------
    // private
    onEntitiesDeleted = (deletedEntityRTIDs) =>
    {
        const viewportsToRelease = this.registeredViewports.filter(
            v => v.getCamera() && deletedEntityRTIDs.includes(v.getCamera().getID())
        );

        if(viewportsToRelease.length > 0)
        {
            this.registeredViewports = this.registeredViewports.filter(
                v => !viewportsToRelease.some(vv => vv.getId() === v.getId())
            );

            this.updateViewports();
            this.releaseViewports(viewportsToRelease, false, false);
        }
    };

    //--------------------------------------------------------------------------
    releaseViewports(viewports, isOffine, deleteEntities = true)
    {
        const entitiesToDelete = [];

        for(const viewport of viewports)
        {
            const camera = viewport.getCamera();
            if(camera && !camera.isExternal())
            {
                if(camera.controller)
                {
                    this.deleteController(camera.controller);
                }

                entitiesToDelete.push(camera);
            }
            this.defaultCameras[viewport.id] = null;
        }

        if(!deleteEntities || entitiesToDelete.length === 0)
        {
            return;
        }

        if(isOffine)
        {
            const oldCameraRTIDs = entitiesToDelete.map((camera) => camera.UNSAFE_getComponent("euid").rtid);
            this.engineAPI.ftlAPI.deleteEntities(oldCameraRTIDs);
        }
        else
        {
            this.engineAPI.deleteEntities(entitiesToDelete);
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    getControllerType(viewportId)
    {
        const viewport = this.getViewportById(viewportId);
        return viewport.getControllerType();
    }

    //--------------------------------------------------------------------------
    // deprecated
    setControllerType(viewportId, controllerType)
    {
        const viewport = this.getViewportById(viewportId);
        viewport.setControllerType(controllerType);
    }

    //--------------------------------------------------------------------------
    // private
    async getUserName()
    {
        if(SDK3DVerse.webAPI.apiToken && SDK3DVerse.webAPI.getUserProfile)
        {
            if(!this.usernamePromise)
            {
                this.usernamePromise = SDK3DVerse.webAPI.getUserProfile()
                .then(profile =>
                {
                    return profile.username;
                })
                .catch((error) => {console.error(error)});
            }

            return await this.usernamePromise;
        }

        return "No name";
    }

    //--------------------------------------------------------------------------
    // private
    async createOrGetDefaultCamera(viewport, transform, controllerType, cameraComponent, perspectiveLensComponent, attachedCamera, isOffline)
    {
        let camera = this.defaultCameras[viewport.id];

        if(!camera)
        {
            const userName      = isOffline ? "offline" : await this.getUserName();
            const cameraName    = userName + " camera " + (viewport.id + 1);
            const isPerspective = viewport.hasPerspectiveProjection();

            const entityTemplate = {
                ... SDK3DVerse_Utils.clone(this.defaultCameraTemplate),
                debug_name : { value : cameraName }
            };

            if(attachedCamera)
            {
                entityTemplate.camera = SDK3DVerse_Utils.clone(attachedCamera.getComponent('camera'));
                entityTemplate.perspective_lens = SDK3DVerse_Utils.clone(attachedCamera.getComponent('perspective_lens'));
            }
            else
            {
                if(cameraComponent)
                {
                    entityTemplate.camera = SDK3DVerse_Utils.clone(cameraComponent);
                }
                else
                {
                    entityTemplate.camera = this.getDefaultCameraValues(isOffline);
                }

                if(entityTemplate.camera.renderGraphRef === DEPRECATED_RENDER_GRAPH)
                {
                    entityTemplate.camera.renderGraphRef = DEFAULT_RENDER_GRAPH;
                }
            }

            if(isPerspective)
            {
                if(perspectiveLensComponent)
                {
                    entityTemplate.perspective_lens = SDK3DVerse_Utils.clone(perspectiveLensComponent);
                }
                entityTemplate.perspective_lens.aspectRatio = viewport.aspectRatio;
            }
            else
            {
                delete entityTemplate.perspective_lens;
                entityTemplate.orthographic_lens = viewport.orthographicLens;
            }

            if(transform)
            {
                entityTemplate.local_transform = transform;
            }
            else if (attachedCamera)
            {
                entityTemplate.local_transform = SDK3DVerse_Utils.clone(SDK3DVerse_Utils.getIdentityTransform());
            }
            else
            {
                entityTemplate.local_transform = await this.getDefaultCameraTransform(
                    isPerspective && entityTemplate.perspective_lens.fovy,
                    isOffline
                );
            }

            if(isOffline)
            {
                camera = await this.engineAPI.transientEntity(entityTemplate);
            }
            else
            {
                camera = await this.engineAPI.spawnEntity(attachedCamera, entityTemplate);
            }

            const isEnabled = this.currentViewportEnabled ? (this.currentViewportEnabled.id == viewport.id) : (viewport.id == 0);

            if(controllerType !== controller_type.none)
            {
                camera.controller = this.createController(controllerType, camera, isEnabled);
            }

            if(isEnabled && this.currentViewportEnabled == null)
            {
                this.currentViewportEnabled = viewport;
            }

            this.defaultCameras[viewport.id] = camera;
            camera.isCreatedByClient = true;
        }
        else if(camera.getParent() !== attachedCamera)
        {
            camera.setComponent('camera', attachedCamera.getComponent('camera'));
            camera.setComponent('local_transform', SDK3DVerse_Utils.getIdentityTransform());
            await this.engineAPI.reparentEntity(camera.getID(), attachedCamera ? attachedCamera.getID() : null);
        }

        return camera;
    }

    //--------------------------------------------------------------------------
    getDefaultCameraValues(isOffline)
    {
        isOffline = typeof isOffline === 'boolean' ? isOffline : this.isOffline;
        const { sceneSettings } = SDK3DVerse.engineAPI.editorAPI;
        if(!isOffline && sceneSettings.hasOwnProperty("default_camera_component"))
        {
            return SDK3DVerse_Utils.clone(sceneSettings.default_camera_component);
        }

        return SDK3DVerse_Utils.clone(this.defaultCameraTemplate.camera);
    }

    //--------------------------------------------------------------------------
    async getDefaultCameraTransform(fovy, isOffline)
    {
        if(!isOffline && SDK3DVerse.engineAPI.editorAPI.sceneSettings.hasOwnProperty("default_camera_transform"))
        {
            return {
                ...SDK3DVerse.engineAPI.editorAPI.sceneSettings.default_camera_transform,
                scale : [1.0, 1.0, 1.0]
            };
        }

        const defaultTransform = {
            position   : [ 0.0, 1.0, 5.0 ],
            orientation: [ 0.0, 0.0, 0.0, 1.0 ],
            scale      : [ 1.0, 1.0, 1.0 ]
        };

        if(!fovy)
        {
            return defaultTransform;
        }

        try
        {
            const { min = [0, 0, 0], max = [0, 0, 0] } = await SDK3DVerse.webAPI.getSceneAABB();

            if(!SDK3DVerse_Utils.isAABBValid({min, max}))
            {
                throw new Error('AABB with Infinite numbers');
            }

            const cameraPosition = [
                (max[0] + min[0]) / 2,
                (max[1] - min[1]) / 2,
                max[2]
            ];

            const aabb = SDK3DVerse_Utils.computeAABB({min, max});

            if(aabb.longestAxisSize > 0)
            {
                const {
                    targetPosition,
                    targetOrientation
                } = SDK3DVerse_Utils.lookAtAABB(aabb, cameraPosition, fovy);

                defaultTransform.position      = targetPosition;
                defaultTransform.orientation   = targetOrientation;
            }
        }
        catch(error)
        {
            console.warn(error);
        }

        return defaultTransform;
    }

    //--------------------------------------------------------------------------
    // private
    createController(controllerType, entity, isEnabled)
    {
        this.engineAPI.ftlAPI.createController(controllerType, entity.UNSAFE_getComponent("euid").rtid, isEnabled);

        return {
            id      : this.globalControllerID++,
            type    : controllerType
        };
    }

    //--------------------------------------------------------------------------
    // private
    deleteController(controller)
    {
        this.engineAPI.ftlAPI.deleteController(controller.id);
    }

    //--------------------------------------------------------------------------
    // private
    updateViewportsProjection = (newWidth, newHeight) =>
    {
        this.registeredViewports.forEach((viewport) =>
        {
            viewport.computeProjectionData(newWidth, newHeight);

            if(viewport.getCamera())
            {
                viewport.updateProjectionMatrix(!this.isOffline);
            }
        });

        if(!this.isOffline)
        {
            this.commitCameraChanges();
        }
    }

    //--------------------------------------------------------------------------
    // private
    commitCameraChanges()
    {
        const cameras = this.registeredViewports
            .map(v => v.getCamera())
            .filter(camera => Boolean(camera));
        const entities = cameras.map(c => c.overrider ? c.overrider : c);
        this.engineAPI.commitChanges(entities);
    }

    //--------------------------------------------------------------------------
    // private
    onFrameDataReceived = (frameData) =>
    {
        if(!frameData.clientUUID)
        {
            return;
        }

        this.currentClientUUID  = frameData.clientUUID
        const clientViewports   = frameData.clients[this.currentClientUUID].viewports;
        const updatedCameras    = [];

        for(const viewportMetadata of clientViewports)
        {
            const viewportsAttached = this.registeredViewports.filter((viewport) =>
            {
                return viewportMetadata.cameraRTID == (viewport.cameraEntity ? viewport.cameraEntity.getID() : "0");
            });

            for(const viewport of viewportsAttached)
            {
                if(!viewport.disableUpdate)
                {
                    viewport.setWorldMatrix(viewportMetadata.worldMatrix);
                    updatedCameras.push(viewport.cameraEntity);
                }
            }
        }

        this.clientViewportsMap = {};

        for(const clientUUID in frameData.clients)
        {
            if(clientUUID == this.currentClientUUID)
            {
                continue;
            }

            this.clientViewportsMap[clientUUID] = frameData.clients[clientUUID].viewports.map(v =>
            ({
                ...v,
                transform : SDK3DVerse_Utils.matrixToTransform(v.worldMatrix)
            }));
        }

        if(updatedCameras.length > 0)
        {
            this.notifier.emit("OnCamerasUpdated", updatedCameras);
        }
    }

    //--------------------------------------------------------------------------
    // private
    refresh()
    {
        if(this.currentViewportEnabled && this.currentViewportEnabled.cameraEntity)
        {
            const camera = this.currentViewportEnabled.cameraEntity;
            camera.UNSAFE_setComponent('local_transform', this.currentViewportEnabled.getTransform());
            camera.UNSAFE_setDirty('local_transform');
            this.engineAPI.ftlAPI.propagateChanges([camera]);
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    travel(viewport, destinationPosition, destinationOrientation, speed, startPosition, startOrientation)
    {
        this.travelingViewport = viewport;
        return viewport.travel(destinationPosition, destinationOrientation, speed, startPosition, startOrientation);
    }

    //--------------------------------------------------------------------------
    // deprecated
    stopTravel()
    {
        if(!this.travelingViewport)
        {
            return;
        }

        this.travelingViewport.stopTravel();
    }

    //--------------------------------------------------------------------------
    // deprecated
    teleport(clientUUID, speed, viewport)
    {
        if(!viewport)
        {
            if(!this.currentViewportEnabled)
            {
                console.warn('No active viewport to teleport');
                return;
            }

            viewport = this.currentViewportEnabled;
        }

        return viewport.travelToClient(clientUUID, speed);
    }

    //--------------------------------------------------------------------------
    /**
     * Get the camera entities of the clients in session.
     *
     * @param {string} clientUUID Client unique identifier. See [getClientUUIDs]{@link SDK3DVerse#getClientUUIDs}
     * @returns {Array.<Entity>} The camera entities of the client.
     *
     * @method SDK3DVerse.engineAPI.cameraAPI#getClientCameras
     */
    getClientCameras(clientUUID)
    {
        if(!this.clientViewportsMap.hasOwnProperty(clientUUID))
        {
            return null;
        }

        return this.clientViewportsMap[clientUUID]
            .map(({ cameraRTID }) => this.engineAPI.getEntity(cameraRTID));
    }

    //--------------------------------------------------------------------------
    // private
    getClientUUIDs()
    {
        return Object.keys(this.clientViewportsMap);
    }

    //--------------------------------------------------------------------------
    // private
    getClientsViewports()
    {
        return this.clientViewportsMap;
    }
}

/**
 * Settings used by camera controllers. See [camera controller types]{@link SDK3DVerse#cameraControllerType}.
 * They can be updated using [updateControllerSettings]{@link SDK3DVerse.engineAPI.cameraAPI#updateControllerSettings}.
 * 
 * @typedef CameraControllerSettings
 * @property {number} speed Speed in meters per second. Default depends on `defaultCameraSpeed` passed to [startSession]{@link SDK3DVerse#startSession},
 *                          [joinSession]{@link SDK3DVerse#joinSession}, [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}
 * @property {number} sensitivity=0.1 Input sensitivity, can also be thought of as rotation speed
 * @property {number} damping=0.65 Damping factor used to damp, or slow down camera movement
 * @property {number} angularDamping=0.65 Damping factor used to damp, or slow down camera rotation
 * @property {SDK_Vec3} lookAtPoint For an orbit camera controller, explicity sets the center that
 *                                 the camera orbits around. lookAtPoint is in global space. By default, the lookAtPoint is the clicked point.
 *                                  For other camera controllers, lookAtPoint does nothing
 * 
 * @example
 * {
 *     speed: 4.0,
 *     sensitivity: 0.1,
 *     damping: 0.65,
 *     angularDamping: 0.65,
 *     lookAtPoint: [0, 0, 0]
 * }
 */

/**
 * Viewport properties used to create a {@link Viewport}.
 *
 * @typedef ViewportInfo
 * @property {uint} id The identifier of the viewport
 * @property {number} width Viewport width ratio (from 0.0 to 1.0) within the display area. See [setResolution]{@link SDK3DVerse#setResolution}
 * @property {number} height Viewport height ratio (from 0.0 to 1.0) within the display area. See [setResolution]{@link SDK3DVerse#setResolution}
 * @property {number} left Viewport left border position ratio (from 0.0 to 1.0) within the display area
 * @property {number} top Viewport top border position ratio (from 0.0 to 1.0) within the display area
 * @property {Entity} [camera=null] An already existing camera entity which will be the camera associated to the created viewport. Otherwise a default camera is created.
 *                  If specified, then `defaultControllerType` and `onCameraCreation` are ignored.
 * @property {int} [defaultControllerType=[SDK3DVerse.cameraControllerType.editor]{@link SDK3DVerse#cameraControllerType}] The type of controller 
 *                  associated to the default camera. The controller will move camera according to client inputs. Can be omitted if developer wants to 
 *                  implement their own controller, or if no controller is wanted. See [SDK3DVerse.cameraControllerType]{@link SDK3DVerse#cameraControllerType}
 * @property {function(Entity):void} [onCameraCreation] A function called when the default camera entity of the viewport has been created. The created camera {@link Entity} 
 *                  is passed to the function. Please note this function may be called twice for the same viewport and the same camera entity
 * @example
 * // The viewport created from these properties will cover the entire canvas, and a default camera
 * // with an orbit controller will be created and associated to the viewport.
 * {
 *      id: 0,
 *      width: 1.0,
 *      height: 1.0,
 *      left: 0.0,
 *      top: 0.0,
 *      defaultControllerType: SDK3DVerse.cameraControllerType.orbit,
 *      onCameraCreation: (cameraEntity) => { console.log(cameraEntity.getName(), "was created") }
 * }
 * @example
 * // The viewport created from these properties will cover the entire canvas, and the camera
 * // associated to the viewport will be the cameraEntity passed. No controller is given to the
 * // camera, the developer may implement one themselves if needed.
 * {
 *      id: 0,
 *      width: 1.0,
 *      height: 1.0,
 *      left: 0.0,
 *      top: 0.0,
 *      camera: cameraEntity,
 * }
 * @see [setViewports]{@link SDK3DVerse.engineAPI.cameraAPI#setViewports}
 * @see [startSession]{@link SDK3DVerse#startSession}
 * @see [joinSession]{@link SDK3DVerse#joinSession}
 * @see [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}
*/

/**
 * @callback ViewportMouseEventListener
 * @param {Viewport} viewport The Viewport object
 * @param {MouseEvent} event The [MouseEvent]{@link https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent} object
 */

export default SDK3DVerse_CameraAPI;
